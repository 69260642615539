'use client';

import { Button } from '@dop-ui/react/shared/ui/button';
import {
  BellIcon,
  EarthIcon,
  FolderRefundIcon,
  LinkPlusIcon,
  MegaphoneIcon,
  QuestionCircleIcon,
  SettingsIcon,
  UserCircleIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { DefaultProfileCard as ProfileCard } from '@dop-ui/react/widgets/profile-card';
import { CursorArrowRaysIcon } from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import { useTranslation } from '../../lib/i18n/client/useTranslation';
import { GlobalConfigDialog } from '../GlobalConfig';
import { Item } from './Item';

import { UserNotification } from '@dop-ui/react/widgets/user-notification';

import '@daouoffice/design/dist/template/snb.css';

export type ServiceTypes =
  | 'employee' // 임직원 포탈
  | 'management'; // 경영업무 포탈

export type RoleTypes =
  | 'user' // 일반 사용자
  | 'admin'; // 관리자

export type SubRoleTypes = 'restoreAdmin'; // 삭제 복구 센터 관리자

export interface UserData {
  name: string;
  email: string;
  gradeName: string;
  departmentNames: string[];
  avatarUrl?: string;
  /** @property 기본 색상 (아바타 배경 색으로 이용) */
  color?: string;
}

export interface Props {
  /** @property 아바타 유저 정보 */
  user?: UserData | null;
  className?: string;
  /** @property 타겟 서비스. ep: 임직원포탈, mp: 경영업무포탈 */
  service?: ServiceTypes;
  /** @property 역할 (임시). user: 일반 사용자, admin: 관리자 */
  roleType?: RoleTypes;
  /** @property 서브 역할. roleType의 하위 분류 역할을 추가로 검증해야 할 경우 사용. (ex: 삭제 복구 센터 관리자) */
  subRoleType?: SubRoleTypes;
}

export function Toolbar({
  user,
  className,
  service,
  roleType = 'user',
  subRoleType,
}: Props) {
  const { t } = useTranslation();
  const isAdmin = roleType === 'admin';
  // 포탈간 이동 버튼 노출 조건
  // - 임직원 포탈이고 역할이 admin일 경우
  // - 경영업무 포탈에서는 항상 노출
  const isShowMngPortalBtn = !(service === 'employee' && roleType === 'user');
  const portalBtnLabel =
    service === 'management'
      ? t('service.employeePortal.name')
      : t('service.businessPortal.name');
  const jumpTo = () => {
    const targetUrl = service === 'management' ? '/' : '/eacc/app/';
    const targetId = `dop-${
      service === 'management' ? 'emp-portal-front' : '/acc-portal-front'
    }`;
    window.open(targetUrl, targetId);
  };

  return (
    <div className="snb">
      <ul className={clsx('toolbar flex justify-center', className)}>
        {/** 고객 포탈 바로가기 */}
        {isAdmin && (
          <Item title={t('service.customerPortal.name')}>
            <UserCircleIcon />
          </Item>
        )}

        {/** 삭제 복구 센터 바로가기 */}
        {isAdmin && subRoleType === 'restoreAdmin' && (
          <Item title={t('service.archiving.name')}>
            <FolderRefundIcon />
          </Item>
        )}

        {/** 통합설정 바로가기 */}
        {isAdmin && (
          <GlobalConfigDialog>
            <Item title={t('service.integratedSettings.name')}>
              <SettingsIcon />
            </Item>
          </GlobalConfigDialog>
        )}

        {/** 링크 플러스 바로가기 */}
        <Item title={t('service.linkplus.name')}>
          <LinkPlusIcon />
        </Item>

        {/** 서비스 공지사항 바로가기 */}
        <Item title={t('service.notices.name')}>
          <MegaphoneIcon />
        </Item>

        {/** 통합알림 바로가기 */}

        <UserNotification>
          <Item title={t('service.notification.name')}>
            <BellIcon />
          </Item>
        </UserNotification>

        {/** 가이드 바로가기 */}
        <Item title={t('service.guide.name')} hasChildren>
          <QuestionCircleIcon />
        </Item>

        {/** 경영업무 포탈 > 위저드 마법사 바로가기 */}
        {service === 'management' && (
          <Item title={t('service.businessPortalWizard.name')}>
            <CursorArrowRaysIcon className="w-6 h-6" />
          </Item>
        )}
      </ul>

      {isShowMngPortalBtn && (
        <Button
          shape="round"
          size="md"
          variant="solid"
          colorset="level1"
          onClick={jumpTo}
        >
          <EarthIcon />
          <span className="text-[14px]">{portalBtnLabel}</span>
        </Button>
      )}

      {/* 프로필(아바타) */}
      {user && <ProfileCard />}
    </div>
  );
}

export default Toolbar;
