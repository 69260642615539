import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { ProgressBar } from './components/progress-bar';

interface Props {
  startDateString: string;
  expireDateString: string;
}

export function RemainUsePeriodInfo({
  startDateString,
  expireDateString,
}: Props) {
  const { t } = useTranslation('component');

  const startDate = new Date(startDateString);
  const expireDate = new Date(expireDateString);
  const currentDate = new Date();

  const totalTime = expireDate.getTime() - startDate.getTime();
  const usedTime = currentDate.getTime() - startDate.getTime();

  function getRemainMonthsAndDaysFromNow(expireDate: Date) {
    let months =
      (expireDate.getFullYear() - currentDate.getFullYear()) * 12 +
      (expireDate.getMonth() - currentDate.getMonth());
    let days = expireDate.getDate() - currentDate.getDate();

    if (days < 0) {
      months--;
      const previousMonth = new Date(
        expireDate.getFullYear(),
        expireDate.getMonth(),
        0,
      );
      days += previousMonth.getDate();
    }

    return { months, days };
  }

  const remainDate = getRemainMonthsAndDaysFromNow(expireDate);

  return (
    <div className="border border-solid border-[#E6E7EA] rounded-[8px] px-[32px] 2xl:basis-1/3 flex-grow">
      <p className="mt-[32px] ml-[8px] text-[#333333] text-[18px] font-[500]">
        {t('globalconfig.basicManagement.serviceInfo.usePeriodInfo.title')}
      </p>
      <p className="mt-[16px] ml-[8px] text-[#333333] text-[36px] font-[500]">
        ~{expireDate.getFullYear()}.
        {(expireDate.getMonth() + 1).toString().padStart(2, '0')}.
        {expireDate.getDate().toString().padStart(2, '0')}
      </p>
      <p className="mt-[48px] text-[#AAAAAA] text-[14px] font-[500]">
        {t('globalconfig.basicManagement.serviceInfo.usePeriodInfo.remainUse')
          .replace('{{month}}', `${remainDate.months}`)
          .replace('{{day}}', `${remainDate.days}`)}
      </p>
      <ProgressBar
        className="w-full mt-[8px]"
        total={totalTime}
        used={usedTime}
      />
      <a
        href="https://my.daouoffice.com/login"
        rel="noreferrer"
        target="_blank"
      >
        <div className="relative flex items-center justify-center mt-[16px] mb-[30px] py-[12px] w-full border border-solid border-[#9A9CA0] rounded-[8px]">
          <p className="text-[#888888] text-[14px] font-[500]">
            {t(
              'globalconfig.basicManagement.serviceInfo.usePeriodInfo.linkToDaou',
            )}
          </p>
          <ArrowTopRightOnSquareIcon className="absolute size-[16px] top-[13px] right-[11px] bottom-[11px]" />
        </div>
      </a>
    </div>
  );
}
