import { Avatar } from '../../../shared/ui/avatar';
import * as ProfileCardPrimitives from './primitives';
import { getRandomColor } from '../../../shared/lib/utils';
import * as Tooltip from '../../../shared/ui/tooltip';
import { useSessionContext } from '../../../features/authenticate';

import '@daouoffice/design/dist/template/profilecard.css';

export function ProfileCard() {
  const { me: userData } = useSessionContext();

  if (!userData) {
    return null;
  }
  const userName = userData.name ?? 'no-name';
  const randomColor = getRandomColor(userData.id);

  return (
    <ProfileCardPrimitives.Root>
      <ProfileCardPrimitives.Content>
        <ProfileCardPrimitives.Trigger>
          <Tooltip.Root>
            <Tooltip.Trigger>
              <div className="avatar_medium">
                <Avatar
                  size={36}
                  alt={userName}
                  src={userData.profileImageUrl}
                  backgroundColor={randomColor}
                />
              </div>
            </Tooltip.Trigger>
            {/* 반드시 Portal로 띄워야 오거나이저의 패널 뒤에 숨지 않음. 추후 z-index 정리 필요 */}
            <Tooltip.Portal>
              <Tooltip.Content align="center" side="bottom" sideOffset={5}>
                {userName}
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </ProfileCardPrimitives.Trigger>
        <ProfileCardPrimitives.Card
          profileInfo={{
            name: userName,
            gradeName: userData.gradeName ?? 'no-grade',
            departmentNames: [userData.departmentInfo.name ?? 'no-department'],
            email: userData.email ?? 'no-email',
            color: randomColor,
          }}
        />
      </ProfileCardPrimitives.Content>
    </ProfileCardPrimitives.Root>
  );
}
