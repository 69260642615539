import { XMarkBorderIcon } from '@dop-ui/icons/react/dop/24';
import {
  uploadCommonFile,
  UploadResponse,
} from '@dop-ui/react/features/file-upload';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { isContentNeedsSaveAtom } from '../../../../../store/isContentNeedsSaveAtom';
import { ImageInfo } from '../../types';
import { ImageCropperDialog } from '../image-cropper-dialog';

export interface Props {
  title: string;
  isFavicon?: boolean;
  withTooltip?: string;
  className?: string;
  value?: ImageInfo | null;
  onDelete?: () => void;
  onChange?: (value: ImageInfo | null) => void;
  onChangePath?: (path: string) => void;
}

export function ImageFormField({
  title,
  isFavicon = false,
  withTooltip,
  className,
  value,
  onDelete,
  onChange,
}: Props) {
  const { t } = useTranslation('component');
  const inputRef = useRef<HTMLInputElement>(null);
  const [cropperOpenState, setCropperOpenState] = useState<boolean>(false);
  const [preview, setPreview] = useState<string | null>(value?.path ?? null);
  const [imageInfo, setImageInfo] = useState<ImageInfo | null>(value ?? null);
  const [, setContentNeedSaveState] = useAtom(isContentNeedsSaveAtom);
  const [tempImageInfo, setTempImageInfo] = useState<ImageInfo | null>(null);
  const uploadMutation = useMutation({
    mutationKey: ['uploadCommonFile', 'imageFormField'],
    mutationFn: async (image: ImageInfo) => {
      if (image?.originFile) {
        const res = (await uploadCommonFile(
          image?.originFile,
        )) as UploadResponse;

        if (res?.filePath) {
          onChange?.({
            ...image,
            path: res.filePath,
            previewPath: res.thumbsPath.attachThumbnailOriginal,
            name: res.fileName,
          } as ImageInfo);
        }
      }
    },
    onSuccess: () => setContentNeedSaveState(true),
  });

  const uploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    const file = e.target.files?.[0];

    if (file) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setCropperOpenState(true);
        setTempImageInfo({
          name: file.name ?? '',
          path: fileReader.result as string,
          originFile: file,
        });
      };

      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const onDeleteHandler = () => {
    onDelete?.();
  };

  const cropperConfirmHandler = (blob: Blob) => {
    const imgURL = URL.createObjectURL(blob);
    setPreview(imgURL);
    setCropperOpenState(false);
    uploadMutation.mutate({
      name: tempImageInfo?.name ?? '',
      path: imgURL,
      previewPath: imgURL,
      originFile: new File([blob], tempImageInfo?.name ?? '') ?? null,
    });
  };

  useEffect(() => {
    setTempImageInfo(value ?? null);
    setPreview(value?.previewPath ?? null);
    setImageInfo(value ?? null);
  }, [value]);

  return (
    <Tooltip.Root>
      <div className={className}>
        {tempImageInfo && (
          <ImageCropperDialog
            title={t(
              'globalconfig.basicManagement.logoThemeManagement.dialog.title',
            ).replace('{imageName}', title)}
            subTitle={
              isFavicon
                ? t(
                    'globalconfig.basicManagement.logoThemeManagement.dialog.ico',
                  )
                : undefined
            }
            aspect={isFavicon ? 1 : undefined}
            image={tempImageInfo}
            isOpen={cropperOpenState}
            openStateChange={setCropperOpenState}
            onConfirm={cropperConfirmHandler}
          />
        )}
        <div className="flex gap-4 items-center">
          <div className="flex items-center gap-1 w-[200px] ">
            <p className="text-[#363636] font-medium leading-[21px] -tracking-[0.28px] text-[14px]">
              {title}
            </p>
            {withTooltip && (
              <Tooltip.Trigger>
                <QuestionMarkCircleIcon
                  className="size-[20px]"
                  color="#AAAAAA"
                />
              </Tooltip.Trigger>
            )}
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-2">
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  ref={inputRef}
                  className="hidden"
                  type="file"
                  accept={isFavicon ? '.ico' : '.jpg, .jpeg, .png, .bmp, .gif'}
                  onChange={uploadHandler}
                />
                <span className="flex items-center select-none px-3 h-10 text-[14px] w-[322px] border border-[#D8D8D8] rounded-lg">
                  {imageInfo?.name ??
                    t(
                      'globalconfig.basicManagement.logoThemeManagement.uploadFile.title',
                    )}
                </span>
                <span className="flex items-center select-none text-[14px] h-10 leading-[150%] -tracking-[0.28px] font-normal text-[#363636] !border-[#C5C5C5] border border-solid rounded-lg px-4">
                  {t(
                    'globalconfig.basicManagement.logoThemeManagement.uploadButton.title',
                  )}
                </span>
              </label>
            </div>
          </div>
        </div>

        {preview && (
          <div className="ps-[216px] pt-4">
            <div
              className={clsx('relative pt-2 pe-2', {
                'w-[150px] h-[100px]': !isFavicon,
                'w-[62px] h-[62px]': isFavicon,
              })}
            >
              <img
                className="border object-cover border-solid border-[#EEEEEE] rounded-lg"
                src={preview}
                alt={''}
                loading="lazy"
                width={isFavicon ? 100 : 150}
                height={100}
              />
              {value?.type !== 'default' && (
                <Button
                  className="absolute !bg-transparent top-0 right-0"
                  onClick={onDeleteHandler}
                >
                  <XMarkBorderIcon className="size-6" />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {withTooltip && <Tooltip.Content>{withTooltip}</Tooltip.Content>}
    </Tooltip.Root>
  );
}

export default ImageFormField;
