import { IconContainer } from '@dop-ui/icons/react/dop/IconContainer';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useTranslation } from '../../../shared/lib/i18n/client/use-translation';
import { SimpleCodeFormPrimitives } from '../index';
import { useValuesContext } from './primitives/provider';

export interface Props {
  /** @property title 텍스트 */
  title?: string;
  /** @property className */
  className?: string;
  /** @property onSuccess 함수 */
  onSuccess?: () => void;
  /** @property onClick 함수 */
  onClick?: (e: React.MouseEvent) => void;
}

export function SimpleCodePasswordChecker({
  className,
  // onSuccess,
  onClick,
}: Props) {
  const { t } = useTranslation('component');
  const [password, setPassword] = useState('');
  const { errorMessage } = useValuesContext();
  // const { setErrorMessage } = useActionsContext();

  const enterKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && checkHandler();

  const checkHandler = () => handleOnCheckPassword();

  const handleOnCheckPassword = () => {
    // TODO. API 개발완료 되면 연동 필요.
  };

  const handleOnPasswordChange = (password: string) => {
    setPassword(password);
  };

  // const setErrorMessageByTextChanged = (text: string) => {
  //   text.length > 4
  //     ? setErrorMessage(t('simplecode.error.max.length'))
  //     : isNaN(Number(text))
  //       ? setErrorMessage(t('simplecode.error.not.number'))
  //       : setErrorMessage('');
  // };

  // const onSuccessHandler = () => {
  //   onSuccess && onSuccess();
  // };

  return (
    <SimpleCodeFormPrimitives.Contents className={className}>
      <SimpleCodeFormPrimitives.Title
        title={t('screenlock.title')}
        className="text-3xl pb-16"
      />
      <IconContainer size={50}>
        <LockClosedIcon />
      </IconContainer>
      <SimpleCodeFormPrimitives.InfoMessage
        message={t('simplecode.placeholder.password')}
        className="mb-4 pt-4 text-primary"
      />

      <SimpleCodeFormPrimitives.TextField
        value={password}
        onTextChange={handleOnPasswordChange}
        onKeyPress={enterKeyHandler}
        placeholder={t('simplecode.placeholder.password')}
      />
      <SimpleCodeFormPrimitives.ErrorMessage
        message={errorMessage ?? ''}
        className="mb-4"
      />

      <SimpleCodeFormPrimitives.ActionButton
        title={t('screenlock.unlock')}
        handleOnAction={checkHandler}
      />
      <SimpleCodeFormPrimitives.InfoMessage
        message={t('screenlock.unlcok.by.simple.code')}
        className="pt-3 text-right text-gray-300"
        linkUrl="/simplecode-register"
        onClick={onClick}
      />
    </SimpleCodeFormPrimitives.Contents>
  );
}

export default SimpleCodePasswordChecker;
