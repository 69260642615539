import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { DatePicker } from '@dop-ui/react/shared/ui/date-picker';
import * as FilterPrimitives from '@dop-ui/react/shared/ui/filter';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { getCompanySchedule, QUERY_KEY } from './apis/schedule-manage';
import { DeleteScheduleDialog } from './components/delete-schedule-dialog';
import { LoadHolidaysConfirmDialog } from './components/load-holidays-confirm-dialog';
import { ScheduleDialog } from './components/schedule-dialog';
import { ScheduleTypeChip } from './components/schedule-type-chip';
import {
  HolidayType,
  ScheduleInfo,
  ScheduleSortType,
  ScheduleType,
} from './types';
import { dayOfWeekToLocalizedCode } from './utils/day-of-week';

export function CompanyScheduleManagement() {
  const { t } = useTranslation('component');
  const MIN_DATE = new Date(new Date().getFullYear() - 10, 0, 1);
  const MAX_DATE = new Date(new Date().getFullYear() + 2, 11, 31);
  const [searchingDate, setSearchingDate] = useState<Date>(new Date());
  const searchingYear = searchingDate.getFullYear();
  const [selectedScheduleInfo, setSelectedScheduleInfo] = useState<
    ScheduleInfo | undefined
  >();
  const [selectedScheduleInfoList, setSelectedScheduleInfoList] = useState<
    ScheduleInfo[]
  >([]);
  const [filterSet, setFilterSet] = useState<Set<string>>(new Set());

  const [sortType, setSortType] = useState<string>('DATE');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

  const { data: scheduleData, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY, searchingYear],
    queryFn: () => getCompanySchedule(searchingYear),
  });

  const [sortedData, setSortedData] = useState<ScheduleInfo[]>([]);
  const [filteredData, setFilteredData] = useState<ScheduleInfo[]>([]);

  useEffect(() => {
    if (!scheduleData) return;

    const sortDate = () => {
      const dateSpecifiedSchedule = scheduleData.filter(
        (data) => data.dateType === 'DATE_SPECIFICATION',
      );
      const daySpecifiedSchedule = scheduleData.filter(
        (data) => data.dateType === 'DAY_SPECIFICATION',
      );

      const sortedDateSpecifiedSchedule = dateSpecifiedSchedule.sort((a, b) => {
        if (a.startDate && b.startDate) {
          if (sortDirection === 'ASC') {
            return a.startDate > b.startDate ? 1 : -1;
          }
          return a.startDate < b.startDate ? 1 : -1;
        } else {
          return 1;
        }
      });

      const sortedDaySpecifiedSchedule = daySpecifiedSchedule.sort((a, b) => {
        if (a.month && b.month) {
          if (sortDirection === 'ASC') {
            return a.month > b.month ? 1 : -1;
          }
          return a.month < b.month ? 1 : -1;
        }
        return 1;
      });

      if (sortDirection === 'ASC') {
        setSortedData([
          ...sortedDateSpecifiedSchedule,
          ...sortedDaySpecifiedSchedule,
        ]);
      } else {
        setSortedData([
          ...sortedDaySpecifiedSchedule,
          ...sortedDateSpecifiedSchedule,
        ]);
      }
    };

    const sortScheduleType = () => {
      const anniversary = scheduleData.filter(
        (data) => data.scheduleType === 'ANNIVERSARY',
      );
      const holiday = scheduleData.filter(
        (data) =>
          data.scheduleType === 'HOLIDAY' &&
          data.holidayType === 'STATUTORY_HOLIDAY',
      );
      const contractHoliday = scheduleData.filter(
        (data) =>
          data.scheduleType === 'HOLIDAY' &&
          data.holidayType === 'CONTRACT_HOLIDAY',
      );

      if (sortDirection === 'ASC') {
        setSortedData([...anniversary, ...holiday, ...contractHoliday]);
      } else {
        setSortedData([...contractHoliday, ...holiday, ...anniversary]);
      }
    };

    const sortName = () => {
      if (sortDirection === 'ASC') {
        const ascSortedData = scheduleData.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setSortedData([...ascSortedData]);
      } else {
        const descSortedData = scheduleData.sort((a, b) =>
          b.name.localeCompare(a.name),
        );
        setSortedData([...descSortedData]);
      }
    };

    const sortPayType = () => {
      const noPayType = scheduleData.filter(
        (data) => data.scheduleType === 'ANNIVERSARY',
      );
      const paid = scheduleData.filter(
        (data) => data.holidayPayType === 'PAID',
      );
      const unpaid = scheduleData.filter(
        (data) => data.holidayPayType === 'UNPAID',
      );

      if (sortDirection === 'ASC') {
        setSortedData([...noPayType, ...paid, ...unpaid]);
      } else {
        setSortedData([...unpaid, ...paid, ...noPayType]);
      }
    };

    switch (sortType) {
      case 'DATE':
        sortDate();
        break;
      case 'SCHEUDLE_TYPE':
        sortScheduleType();
        break;
      case 'NAME':
        sortName();
        break;
      case 'PAY_TYPE':
        sortPayType();
        break;
    }
  }, [scheduleData, sortType, sortDirection]);

  useEffect(() => {
    if (filterSet.size === 0) {
      setFilteredData(sortedData);
      return;
    }
    let filtered: ScheduleInfo[] = [];
    if (filterSet.has('STATUTORY_HOLIDAY')) {
      const statutory = sortedData.filter(
        (data) => data.holidayType === 'STATUTORY_HOLIDAY',
      );
      filtered = [...filtered, ...statutory];
    }
    if (filterSet.has('CONTRACT_HOLIDAY')) {
      const contract = sortedData.filter(
        (data) => data.holidayType === 'CONTRACT_HOLIDAY',
      );
      filtered = [...filtered, ...contract];
    }
    if (filterSet.has('ANNIVERSARY')) {
      const anniversary = sortedData.filter(
        (data) => data.scheduleType === 'ANNIVERSARY',
      );
      filtered = [...filtered, ...anniversary];
    }
    setFilteredData(filtered);
  }, [filterSet, sortedData]);

  if (error) {
    console.error(
      'GlobalConfig > BasicManagement > CompanyScheduleManagement Error:',
      error,
    );
  }

  const CommonHeader = ({
    name,
    type,
  }: {
    name: string;
    type?: ScheduleSortType;
  }) => {
    const chevron = () => {
      if (!type) return null;
      if (sortType !== type)
        return <ChevronUpDownIcon className="ml-[11px] size-[16px]" />;
      if (sortDirection === 'ASC')
        return <ChevronUpIcon className="ml-[11px] size-[16px]" />;
      return <ChevronDownIcon className="ml-[11px] size-[16px]" />;
    };
    return (
      <div
        className="flex items-center w-full text-start"
        role="button"
        tabIndex={0}
        onClick={() => {
          if (type) {
            onChangeSort(type as string);
          }
        }}
        onKeyDown={() => {}}
      >
        {name}
        {chevron()}
      </div>
    );
  };

  const onChangeSort = (type: string) => {
    if (sortType === type) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortType(type);
      setSortDirection('ASC');
    }
  };

  const columnDefs: ColumnDef<ScheduleInfo>[] = [
    {
      id: 'year',
      accessorFn: (info) => (info.year ? info.year : searchingYear),
      header: () => (
        <CommonHeader
          name={t(
            'globalconfig.basicManagement.companySchedule.table.header.year',
          )}
        />
      ),
    },
    {
      id: 'date',
      accessorFn: (info) => {
        const {
          startDate,
          endDate,
          dateType,
          month,
          sequence,
          dayOfWeek,
          calendarRepeatType,
        } = info;

        switch (dateType) {
          case 'DATE_SPECIFICATION':
            if (calendarRepeatType === 'YEAR') {
              if (startDate === endDate) {
                return t(
                  'globalconfig.basicManagement.companySchedule.table.repeat.yearly.dateSpecific',
                ).replace('{{date}}', startDate?.substring(5) ?? '');
              }
              return t(
                'globalconfig.basicManagement.companySchedule.table.repeat.yearly.dateSpecific',
              ).replace(
                '{{date}}',
                `${startDate?.substring(5)} ~ ${endDate?.substring(5)}`,
              );
            }

            return startDate === endDate
              ? startDate?.substring(5)
              : `${startDate?.substring(5)} ~ ${endDate?.substring(5)}`;
          case 'DAY_SPECIFICATION': {
            const day = t(dayOfWeekToLocalizedCode(dayOfWeek ?? 'SUNDAY'));
            const fullMonth = month ? month.toString().padStart(2, '0') : '';
            if (calendarRepeatType === 'YEAR') {
              return t(
                'globalconfig.basicManagement.companySchedule.table.repeat.yearly',
              )
                .replace('{{month}}', fullMonth)
                .replace('{{sequence}}', sequence ? sequence.toString() : '')
                .replace('{{dayOfWeek}}', day);
            }
            return (
              t(
                'globalconfig.basicManagement.companySchedule.table.date.daySpecific',
              )
                .replace('{{month}}', fullMonth)
                .replace('{{sequence}}', sequence ? sequence.toString() : '') +
              ' ' +
              day
            );
          }
        }
      },
      header: () => (
        <CommonHeader
          name={t(
            'globalconfig.basicManagement.companySchedule.table.header.date',
          )}
          type={'DATE'}
        />
      ),
    },
    {
      id: 'scheduleType',
      accessorFn: (info) => ({
        scheduleType: info.scheduleType,
        holidayType: info.holidayType,
      }),
      header: () => (
        <CommonHeader
          name={t(
            'globalconfig.basicManagement.companySchedule.table.header.scheduleType',
          )}
          type={'SCHEUDLE_TYPE'}
        />
      ),
      cell: (info) => {
        const { scheduleType, holidayType } = info.getValue() as {
          scheduleType: ScheduleType;
          holidayType: HolidayType;
        };

        return (
          <ScheduleTypeChip
            scheduleType={scheduleType}
            holidayType={holidayType}
          />
        );
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <CommonHeader
          name={t(
            'globalconfig.basicManagement.companySchedule.table.header.name',
          )}
          type={'NAME'}
        />
      ),
      cell: (info) => (
        <p className="w-full max-w-[282px] overflow-hidden whitespace-nowrap text-ellipsis">
          {info.getValue() as string}
        </p>
      ),
    },
    {
      id: 'isPaid',
      accessorFn: (info) => {
        const { holidayPayType, wageRate } = info;

        if (!holidayPayType) return '-';

        switch (holidayPayType) {
          case 'PAID':
            return wageRate
              ? `${t('globalconfig.basicManagement.companySchedule.schedule.paid')} ${wageRate}%`
              : t('globalconfig.basicManagement.companySchedule.schedule.paid');
          case 'UNPAID':
            return t(
              'globalconfig.basicManagement.companySchedule.schedule.unpaid',
            );
        }
      },
      header: () => (
        <CommonHeader
          name={t(
            'globalconfig.basicManagement.companySchedule.table.header.paidUnpaid',
          )}
          type={'PAY_TYPE'}
        />
      ),
    },
  ];

  return (
    <div className="px-[72px] pb-[40px] pt-[24px]">
      <div className="p-[40px] border border-solid border-[#D8D8D8] //border-[--color-border-level3] rounded-[12px]">
        <p className="py-[4px] text-[#363636] //text-[--color-text-level1] text-[20px] font-[600] leading-[30px]">
          {t('globalconfig.basicManagement.companySchedule.title')}
        </p>
        <div className="flex flex-wrap items-center w-full mt-[24px] gap-[8px]">
          <div className="w-[89px]">
            <DatePicker
              className="p-[24px] w-[296px]"
              showYearPicker
              defaultDate={searchingDate}
              dateFormat="yyyy"
              onDateSelect={(date) => setSearchingDate(date ?? new Date())}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
            >
              <div className="flex items-center h-[40px] px-[16px] border border-solid border-[#D8D8D8] //border-[--color-border-field] rounded-[8px]">
                <CalendarIcon className="size-[20px]" />
                <p className="ml-[4px] text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]">
                  {searchingYear}
                </p>
              </div>
            </DatePicker>
          </div>
          <div className="w-[1px] h-[12px] flex-shrink-0 bg-[#D8D8D8]" />
          <FilterPrimitives.Root name="일정 구분" onChangeValues={setFilterSet}>
            <FilterPrimitives.Trigger />
            <FilterPrimitives.Content className="bg-white z-[1004]">
              <FilterPrimitives.Option
                option={{
                  value: 'STATUTORY_HOLIDAY',
                  label: t(
                    'globalconfig.basicManagement.companySchedule.schedule.holidayType.statutory',
                  ),
                }}
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.holidayType.statutory',
                )}
              </FilterPrimitives.Option>
              <FilterPrimitives.Option
                option={{
                  value: 'CONTRACT_HOLIDAY',
                  label: t(
                    'globalconfig.basicManagement.companySchedule.schedule.holidayType.contract',
                  ),
                }}
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.holidayType.contract',
                )}
              </FilterPrimitives.Option>
              <FilterPrimitives.Option
                option={{
                  value: 'ANNIVERSARY',
                  label: t(
                    'globalconfig.basicManagement.companySchedule.schedule.type.anniversary',
                  ),
                }}
              >
                {t(
                  'globalconfig.basicManagement.companySchedule.schedule.type.anniversary',
                )}
              </FilterPrimitives.Option>
            </FilterPrimitives.Content>
          </FilterPrimitives.Root>
          <div className="flex-grow" />
          <ScheduleDialog
            currentYear={searchingYear}
            info={selectedScheduleInfo}
            onClose={() => setSelectedScheduleInfo(undefined)}
          />
          <DeleteScheduleDialog scheduleInfoList={selectedScheduleInfoList} />
          <div className="w-[1px] h-[12px] flex-shrink-0 bg-[#D8D8D8]" />
          <LoadHolidaysConfirmDialog searchingYear={searchingYear} />
        </div>
        <TablePrimitives.Root<ScheduleInfo>
          className="mt-[16px]"
          contents={filteredData}
          columnDefs={columnDefs}
          selectable
          onClickRow={(index) => setSelectedScheduleInfo(filteredData[index])}
          onSelectContents={setSelectedScheduleInfoList}
        >
          <TablePrimitives.Contents<ScheduleInfo>
            enableRowSelection={(row) => {
              const { scheduleType, dateType, startDate, year, calendarType } =
                row.original;
              if (calendarType === 'SYSTEM' || calendarType === 'SUDDEN')
                return false;

              if (scheduleType === 'HOLIDAY') {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                if (dateType === 'DATE_SPECIFICATION') {
                  const start = new Date(startDate ?? '');
                  if (start < today) return false;
                } else if (dateType === 'DAY_SPECIFICATION') {
                  if ((year ?? -1) < today.getFullYear()) return false;
                }
              }

              return true;
            }}
            emptyNotice={
              <div className="w-full h-[80px] flex items-center justify-center ">
                <span className="text-[--color-text-level3] text-[14px] font-[400]">
                  {t('globalconfig.secureManagement.tfaErrorLog.table.noList')}
                </span>
              </div>
            }
          ></TablePrimitives.Contents>
        </TablePrimitives.Root>
      </div>
    </div>
  );
}
