import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

interface Props {
  value: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  className?: string;
  onChange: (name: string) => void;
}

export function BrowserTitleInput({
  value,
  placeholder = '',
  minLength = 1,
  maxLength = 64,
  className,
  onChange,
}: Props) {
  const [lengthError, setLengthError] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const { t } = useTranslation('component');

  const handleOnBlur = () => {
    setTimeout(() => setOnFocus(false), 10);
  };

  useEffect(() => {
    if (value.length > maxLength || value.length < minLength) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
  }, [value, maxLength, minLength]);

  return (
    <>
      <div className={clsx('flex flex-wrap', className)}>
        <div className="flex-grow">
          <div
            className={clsx(
              'relative h-[40px] border border-solid rounded-[8px] px-[12px]',
              {
                'border-[#E84B4B] //border-[--color-border-caution]':
                  lengthError,
                'border-[#D8D8D8] //border-[--color-border-level1]':
                  !lengthError && !onFocus,
                'border-[#808080] //border-[--color-border-field-active]':
                  !lengthError && onFocus,
              },
            )}
          >
            <input
              className="size-full text-[#363636] //text-[--color-text-level1] text-[14px] font-[400]"
              type="text"
              value={value}
              onChange={(e) =>
                value.length <= maxLength && onChange(e.target.value)
              }
              onBlur={handleOnBlur}
              onFocus={() => setOnFocus(true)}
              tabIndex={0}
              placeholder={placeholder}
            />
            {onFocus && value !== '' && (
              <button
                className="absolute flex items-center justify-center right-[4px] top-[4px] bottom-[4px] w-[32px] p-[4px]"
                onMouseDown={(e) => {
                  e?.preventDefault();
                  onChange('');
                }}
              >
                <XCircleIcon className="size-full" color="#AAAAAA" />
              </button>
            )}
          </div>
          {lengthError && (
            <p className="text-[#E84B4B] //text-[--color-text-caution] text-[13px] font-[400] leading-[19.5px] //leading-[--font-multi-line-height-XSmall]">
              {t(
                'globalconfig.basicManagement.logoThemeManagement.inputErrorMessage',
              )
                .replace('{minLength}', minLength.toString())
                .replace('{maxLength}', maxLength.toString())}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default BrowserTitleInput;
